<template lang="pug">
  div
    v-toolbar(
      color="primary"
      dark
    )
      v-toolbar-title.text-uppercase Customer Forecast - {{ customerData.company || null }}
      v-progress-circular.ml-2(
        v-if="!customerData.company"
        indeterminate
        size="20"
      )
    v-data-table(
      :headers="headers"
      dense
      single-expand
      :items="cxFcstList"
      show-expand
      :loading="cxFcstGetting"
    )
      template(v-slot:item="{ item, expand, isExpanded }")
        tr
          td.px-1
            base-tooltip(
              text
              color="yellow darken-3"
              x-small
              tooltip="edit"
              @click="editItem(item)"
            )
              v-icon mdi-pencil
          td.px-1
            span {{ item.quantity }}
          td.px-1
            span.grey--text {{ ((item.stock_category || {}).name || 'N / A') }}
            span.primary--text(v-if="(item.stock_category || {}).sow_pig_cycle_preference && !(item.sow_pig || {}).piglets") &nbsp;{{ ((item.sow_pig || {}).sow_number) || null }}
            span.success--text(v-if="(item.stock_category || {}).sow_pig_cycle_preference && !(item.sow_pig || {}).piglets") &nbsp;{{ farrowingStatus(item.sow_pig) }}
            span.error--text(v-if="(item.stock_category || {}).sow_pig_cycle_preference && !(item.sow_pig || {}).piglets") &nbsp;{{ farrowingDays(item.sow_pig) }}
            span.orange--text.text--darken-2(v-if="(item.sow_pig || {}).piglets") &nbsp;{{ (item.sow_pig || {}).piglets || 0 }} piglet(s)
          td.px-1 {{ item.days }}
          td.px-1 {{ (item.date_confirmed || '').dateFormat() }}
          td.px-1
            v-btn.pa-0.ma-0(
              text
              x-small
              :class="(isExpanded ? 'is-expanded' : '')"
              color="blue-grey"
              @click="expand(!isExpanded)"
            )
              v-icon mdi-chevron-down
      template(v-slot:expanded-item="{ item, headers }")
        td(:colspan="headers.length")
          v-row.ma-0.border-bottom(
            transition="slide-y-transition"
          )
            v-col.ma-0.py-2(cols="3" align="center")
              small.blue-grey--text Stock
            v-col.ma-0.py-2(cols="3" align="center")
              small.blue-grey--text Type
            v-col.ma-0.py-2(cols="3" align="center")
              small.blue-grey--text Date Start
            v-col.ma-0.py-2(cols="3" align="center")
              small.blue-grey--text Date End
          v-row(v-for="(subItem, index) in item.types" :key="index" :class="index < item.types.length - 1? 'border-bottom' : '' ").ma-0
            v-col(cols="3" align="left").ma-0.py-0.border-right
              small.primary--text {{ subItem.stock.description || 'N/A' }}
            v-col(cols="3" align="left").ma-0.py-0.border-right
              small.primary--text.text-uppercase {{ subItem.feeding_program_category.description || 'N/A' }}
            v-col(cols="3" align="right").ma-0.py-0.border-right
              small.primary--text {{ subItem.date_start.dateFormat() }}
            v-col(cols="3" align="right").ma-0.py-0
              small.primary--text {{ subItem.date_end.dateFormat() }}
</template>
<script>
import cxFcstRespository from '@/repositories/customer-forecast.respository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { getVars, errorHandler, requestVars } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const { objectChangeKeyCase, computeDurationBetweenDates } = Window

const [customerForecastGetVars, customerForecastGetVarNames] = requestVars({ identifier: 'cx-fcst', hasData: false })

const customerForecastGetRequestHandler = new VueRequestHandler(null, customerForecastGetVarNames)

const cxFcstGetDelay = searchDelay()
const expandedSlotDelay = searchDelay(10)
const tableVars = () => ({
  headers: [
    { text: 'Config', sortable: false },
    { text: 'Quantity', value: 'quantity' },
    { text: 'Stock Category', value: 'stock_category_id' },
    { text: 'Days', value: 'days' },
    { text: 'Date Confirmed', value: 'date_confirmed' },
    { text: 'Details', value: 'data-table-expand' },
  ],
})

export default {
  name: 'ForecastTable',
  props: {
    forecastId: {
      type: String,
      default: null,
    },
    customerData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      ...tableVars(),
      ...getVars('cx-fcst'),
      ...customerForecastGetVars,
    }
  },
  created () {
    this.getCxFsct()
    this.websocketEvents()
  },
  computed: {
    customerId () {
      return this.$route.params.customerId
    },
  },
  watch: {
    customerId (val) {
      if (!val) return
      this.getCxFsct()
    },
  },
  methods: {
    linkWithSowNumber (customerForecastId) {
      const { customerId } = this
      // this.$emit('update:forecastId', forecastId)
      this.$router.push({ name: 'customers.forecasts.sow-pigs', params: { customerId, customerForecastId } })
    },
    generateFeedingCalendar ({ id }) {
      this.getCxFsctShow(id)
    },
    getCxFsctShow (id) {
      customerForecastGetRequestHandler.setVue(this)
      const handler = customerForecastGetRequestHandler
      const repository = cxFcstRespository.show
      const params = this.getFeedingCalendarParams()
      handler.execute(repository, [id, params])
    },
    getFeedingCalendarParams () {
      return {
        with: 'types.feedingProgramCategory.details',
      }
    },
    getCxFsct () {
      if (this.cxFcstGetting || !this.customerId) return
      this.cxFcstGetting = true
      this.cxFcstGetErrors = []
      const { customerId } = this
      cxFcstGetDelay(() => {
        cxFcstRespository.customer(customerId)
          .then(({ data }) => { this.cxFcstList = data })
          .catch(e => { this.cxFcstGetErrors = errorHandler(e) })
          .then(() => { this.cxFcstGetting = false })
      })
    },
    editItem ({ id }) {
      const customerForecastId = id
      const { customerId } = this
      this.$router.push({ name: 'customers.forecasts.edit', params: { customerId, customerForecastId } })
    },
    websocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.execPayloadStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    execPayloadStoreEvent ({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'CustomerForecast' && this.customerId === data.customer_id) {
        this.cxFcstList.push(data)
      }
    },
    dbUpdateEvent ({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'SowPig' && this.customerId === data.customer_id) {
        this.sowPigDBUpdateEvent(data)
      }
    },
    sowPigDBUpdateEvent (data) {
      this.cxFcstList = this.cxFcstList.map(item => {
        if (item.id === data.customer_forecast_id) {
          return Object.assign({ ...item, sow_pig: data })
        }
        return Object.assign({ ...item })
      })
    },
    farrowingStatus (args) {
      const { service = null, weaning = null, farrowing = null } = objectChangeKeyCase(args || {})
      if (weaning) return 'Weaning'
      if (farrowing) return 'Farrowing'
      if (service) return 'Servicing'
      return 'N/A'
    },
    farrowingDays (args) {
      const { service = null, weaning = null, farrowing = null } = objectChangeKeyCase(args || {})
      if (weaning) return computeDurationBetweenDates(new Date(), weaning) + ' day(s)'
      if (farrowing) return computeDurationBetweenDates(new Date(), farrowing) + ' day(s)'
      if (service) return computeDurationBetweenDates(new Date(), service) + ' day(s)'
      return 'N/A'
    },
    sowCycleTooltip (args) {
      const { sowPig = {} } = objectChangeKeyCase(args) || {}
      const { service = null, weaning = null, farrowing = null } = sowPig || {}
      if (farrowing) return 'Recycle Sow'
      if (weaning) return 'Proceed To Farrowing'
      if (service) return 'Proceed To Weaning'
      return 'N/A'
    },
  },
}
</script>
<style lang="scss" scope>
  .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
  }
  .border-left {
    border-left: 1px lightgray solid;
  }
  .border-right {
    border-right: 1px lightgray solid;
  }
  .border-bottom {
    border-bottom: 1px lightgray solid;
  }
  .v-btn.is-expanded .v-icon {
    transform: rotate(-180deg);
  }

</style>
